import { styled, TableRow, Tabs } from "@enerbit/base";

interface Props {
  bgcolor: string;
  fontcolor: string;
}

const StyledTableRow = styled(TableRow)<Props>(({ bgcolor, fontcolor }) => ({
  "& th": {
    backgroundColor: `${bgcolor} !important`,
    padding: "3px 5px 3px 5px !important",
    color: `${fontcolor} !important`,
    fontSize: "12px !important",
  },
  "& td": {
    backgroundColor: `${bgcolor} !important`,
    padding: "5px !important",
    color: `${fontcolor} !important`,
    fontSize: "12px !important",
    borderBottom: "unset !important",
  },
}));

export default StyledTableRow;
