import {
  Box,
  Button,
  CloseIcon,
  CustomAlert,
  FormControl,
  List,
  ListItem,
  ListItemText,
  LoadingButton,
  Modal,
  Typography,
} from "@enerbit/base";
import CustomTextField from "../CustomTextfield/CustomTextField";

interface Props {
  handleClose: () => void;
  open: boolean;
  formik: any;
  loading: boolean;
  fecthError: boolean;
  notFound: boolean;
  enlistmentErrors: string[];
  deviceType?: string;
}

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  p: 3,
  borderRadius: "10px",
  width: "500px",
};

const AssociateModal = ({
  open,
  handleClose,
  deviceType = "medidor",
  formik,
  loading,
  fecthError,
  enlistmentErrors,
  notFound,
}: Props) => {
  return (
    <Modal open={open} onClose={handleClose}>
      <Box sx={style}>
        <Box sx={{ display: "flex", alignItems: "center", justifyContent: "space-between", mb: 1 }}>
          <Typography color={"primary"} sx={{ fontSize: "21px", fontWeight: 700 }}>
            Prealistar {deviceType}
          </Typography>
          <Button onClick={handleClose} variant="outlined" className="back-btn">
            <CloseIcon />
          </Button>
        </Box>
        <form onSubmit={formik.handleSubmit} style={{ marginTop: "7px" }}>
          <FormControl fullWidth sx={{ mr: 1 }}>
            <Typography>Serial del {deviceType}</Typography>
            <CustomTextField
              {...formik.getFieldProps("serial")}
              error={!!formik.errors.serial && formik.touched.serial}
              helperText={formik.touched.serial && formik.errors.serial}
              disabled={loading}
            />
          </FormControl>
          {enlistmentErrors.length > 0 && (
            <Box my={2} sx={{ padding: "10px", backgroundColor: "#FEECEB", color: "#F04438" }}>
              <Typography sx={{ fontWeight: 700 }}>
                No se puedo asociar el {deviceType} por los siguientes motivos:
              </Typography>
              <List>
                {enlistmentErrors.map((error, index) => (
                  <ListItem key={index + 1} disablePadding>
                    <Typography variant="body1" component="span" sx={{ mr: 1 }}>
                      •
                    </Typography>
                    <ListItemText primary={error} />
                  </ListItem>
                ))}
              </List>
            </Box>
          )}
          {notFound && (
            <Box my={1}>
              <CustomAlert severity="info" text={deviceType + " no encontrado"} onClose={() => {}} />
            </Box>
          )}
          <LoadingButton fullWidth variant="contained" color="secondary" type="submit" loading={loading} sx={{ mt: 2 }}>
            Prealistar
          </LoadingButton>
        </form>
      </Box>
    </Modal>
  );
};

export default AssociateModal;
