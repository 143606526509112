import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  CircularProgress,
  LoadingButton,
  SxProps,
  Typography,
  styled,
} from "@enerbit/base";
import moment from "moment";
import { SyntheticEvent, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { EVENTS_DELAY, EVENTS_LABELS } from "../../constants";
import { formatEventLog, formatTime } from "../../helpers";
import { useInterval } from "../../hooks/useInterval";
import { IMeterLastReport, MeterData } from "../../models/Meter";
import { EventLogModel } from "../../models/device";
import { IInverterLastReport, InverterData } from "../../models/inverter";
import {
  getInverterEvents,
  getLastReportsInverters,
  getPreEnlistmentInverterLastReports,
} from "../../services/inverters";
import { getLastReports, getMeterEvents, getPreEnlistmentLastReports } from "../../services/meters";
import { AppDispatch, RootState } from "../../store/store";
import { dissasociateMeterFromDevice } from "../../store/thunks/device";
import AccordionButton from "../AccordionButton/AccordionButton";
import ConfirmationModal from "../ConfirmationModal/ConfirmationModal";
import DataComponent from "../DataComponent/DataComponent";
import EmptyEvents from "../EmptyEvents/EmptyEvents";
import EventLog from "../EventLog/EventLog";
import EventsHeader from "../EventsHeader/EventsHeader";
import LastReportData from "../LastReportData/LastReportData";

interface Props {
  meter?: MeterData;
  inverter?: InverterData;
  isPreEnlistment?: boolean;
  meterIndex: number;
}

const MeterCard = ({ meter, inverter, isPreEnlistment, meterIndex }: Props) => {
  const [events, setEvents] = useState<EventLogModel[]>([]);
  const [since, setSince] = useState<string>(moment().startOf("day").format("YYYY-MM-DDTHH:mm:ssZZ"));
  const [until, setUntil] = useState<string>(moment().format("YYYY-MM-DDTHH:mm:ssZZ"));
  const [loading, setLoading] = useState<boolean>(false);
  const [lastReport, setLastReport] = useState<IMeterLastReport | IInverterLastReport>();
  const [expanded, setExpanded] = useState<number | null>(null);
  const [openModal, setOpenModal] = useState<boolean>(false);

  const { deviceDetail, hasPrevMeters } = useSelector((state: RootState) => state.device);

  const dispatch = useDispatch<AppDispatch>();

  const fetchEvents = async () => {
    try {
      const events = meter
        ? await getMeterEvents(meter.serial, since, until)
        : inverter
        ? await getInverterEvents(inverter.serial, since, until)
        : null;
      if (events) {
        const formattedEvents = events.map((ev: any) => formatEventLog(ev, meter ? "meter" : "inverter"));
        setEvents((prev) => {
          const nD = [...prev];
          formattedEvents.reverse().forEach((ev: any) => nD.unshift(ev));
          return nD;
        });
      }
    } catch (error) {
      console.log(error);
    } finally {
      setSince(() => until);
      setUntil(() => moment().add(2, "minute").format("YYYY-MM-DDTHH:mm:ssZZ"));
    }
  };

  const fetchLastReportMeter = async () => {
    if (meter) {
      const res = isPreEnlistment
        ? await getPreEnlistmentLastReports(meter.serial)
        : await getLastReports(meter.serial);
      setLastReport(res);
    }
  };

  const fetchLastReportInverter = async () => {
    if (inverter) {
      const res = isPreEnlistment
        ? await getPreEnlistmentInverterLastReports(inverter.serial)
        : await getLastReportsInverters(inverter.serial);
      setLastReport(res);
    }
  };

  const onDissociateMeter = async () => {
    if (!deviceDetail) return;
    dispatch(dissasociateMeterFromDevice({ deviceId: deviceDetail.id, serial: meter?.serial ?? "", meterIndex }));
    // meter
    //   ? dispatch(dissasociateMeterFromDevice({ deviceId: deviceDetail.id, serial: meter.serial, meterIndex }))
    //   : inverter
    //   ? dispatch(disassociateInverterFromDevice({ deviceId: deviceDetail.id, serial: inverter.serial, meterIndex }))
    //   : null;
  };

  const fetchInitialEvents = async () => {
    await fetchEvents();
    meter ? await fetchLastReportMeter() : inverter ? await fetchLastReportInverter() : null;
  };

  useEffect(() => {
    setLoading(true);
    fetchInitialEvents()
      .catch((error) => console.log(error))
      .finally(() => setLoading(false));
  }, []);

  const { timeLeft } = useInterval(async () => {
    setLoading(true);
    try {
      await fetchEvents();
      meter ? await fetchLastReportMeter() : inverter ? await fetchLastReportInverter() : null;
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  }, EVENTS_DELAY);

  const handleChange = (index: number) => (_: SyntheticEvent, isExpanded: boolean) => {
    setExpanded(isExpanded ? index : null);
  };

  const accordionSummaryStyles: SxProps = {
    // background: "#fff",
    borderRadius: expanded === meterIndex ? "16px 16px 0 0" : "16px",
    height: "auto !important",
    px: 2,
    py: 1,
    border: "1px solid #A3A9B6",
    minHeight: "70px !important",
    ".MuiAccordionSummary-expandIconWrapper": { transform: "none !important" },
  };

  const accordionDetailsStyles: SxProps = {
    p: 2,
    border: expanded === meterIndex ? `1px solid #A3A9B6` : null,
    borderRadius: "0 0 16px 16px",
  };

  return (
    <>
      <Accordion
        expanded={expanded === meterIndex}
        onChange={handleChange(meterIndex)}
        sx={{
          my: 2,
          boxShadow: "unset !important",
          borderRadius: "16px !important",
        }}
      >
        <AccordionSummary
          expandIcon={<AccordionButton expanded={expanded === meterIndex} />}
          sx={accordionSummaryStyles}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              width: "100%",
              pr: "15px",
            }}
          >
            <DataComponent device={meter ?? inverter} lastReport={lastReport} loading={loading} />
            {isPreEnlistment && (
              <LoadingButton
                onClick={(e) => {
                  e.stopPropagation();
                  setOpenModal(true);
                }}
                variant="outlined"
                sx={{ height: "33px !important" }}
              >
                Desasociar
              </LoadingButton>
            )}
          </Box>
        </AccordionSummary>
        <AccordionDetails sx={accordionDetailsStyles}>
          <Box>
            {!loading && (
              <>
                {lastReport ? (
                  <LastReportData lastReport={lastReport as IMeterLastReport} />
                ) : (
                  <Typography>Error obteniendo último reporte</Typography>
                )}
              </>
            )}
            {loading && <Typography>Obteniendo datos del último reporte...</Typography>}
          </Box>
          <Divisor />
          <Box>
            <Box mb={1}>
              <EventsHeader title="Eventos medidor" labels={EVENTS_LABELS} />
            </Box>
            {!loading && (
              <Box mb={1}>
                <Typography sx={{ color: "#667085", fontSize: "14px" }}>
                  Se actualizará en {formatTime(timeLeft)}
                </Typography>
              </Box>
            )}
            <Box
              sx={{
                width: "100%",
                overflowY: "auto",
                maxHeight: "220px",
                paddingRight: 1,
              }}
            >
              {loading && (
                <Box sx={{ display: "flex", alignItems: "center", justifyContent: "center", mb: 1 }}>
                  <CircularProgress sx={{ width: "10px", height: "10px" }} />
                </Box>
              )}
              {events.map((ev, index) => (
                <EventLog
                  key={index}
                  date={ev.date}
                  message={ev.message}
                  bgcolor={ev.bgcolor}
                  fontColor={ev.fontcolor}
                />
              ))}
              {events.length === 0 && !loading ? (
                <EmptyEvents
                  title="Sin eventos de medidor"
                  subtitle="Actualmente no se han registrado eventos para el medidor"
                />
              ) : null}
            </Box>
          </Box>
        </AccordionDetails>
      </Accordion>
      <ConfirmationModal
        title="¿Estás seguro de desasociar el medidor?"
        open={openModal}
        handleClose={() => setOpenModal(false)}
        handleConfirm={onDissociateMeter}
      />
    </>
  );
};

export default MeterCard;

const Divisor = styled("div")(() => ({
  borderBottom: "1px solid #A3A9B6",
  marginTop: "15px",
  marginBottom: "15px",
  marginLeft: "-16px",
  marginRight: "-16px",
}));
