import { api } from "@enerbit/base";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { verifyPreEnlistment, verifyPreEnlistmentInverter } from "../../helpers";
import { MeterData } from "../../models/Meter";
import { DeviceDetail, IAssociateMeter, IDissaciateMeter, IGetAssociatedMeters } from "../../models/device";
import { InverterData } from "../../models/inverter";
import { getAssociatedMeters } from "../../services/device";
import {
  associateInverter,
  dissociateInverter,
  getInverterDetail,
  getLastReportsInverters,
  getPreEnlistmentInverterLastReports,
} from "../../services/inverters";
import {
  associateMeter,
  dissociateMeter,
  getLastReports,
  getMetersDetail,
  getPreEnlistmentLastReports,
} from "../../services/meters";
import { setMeterNotFound, setPreEnlistmentErrors } from "../slices/device";

export const getAssociateMetersByDeviceId = createAsyncThunk(
  "device/getAssociateMetersByDeviceId",
  async ({ deviceId, isPreEnlistment }: IGetAssociatedMeters, { rejectWithValue }) => {
    try {
      const associatedMeters = await getAssociatedMeters(deviceId);
      const requests = associatedMeters.map(async (meter) => {
        const data = await getMetersDetail(meter.meter_serial);
        const lastReport = isPreEnlistment
          ? await getPreEnlistmentLastReports(meter.meter_serial)
          : await getLastReports(meter.meter_serial);
        return { ...data, lastReport };
      });
      const tempMeters = await Promise.all(requests);

      const formattedMeters: MeterData[] = [];

      tempMeters.forEach((meter) => {
        formattedMeters.push({
          id: meter.id,
          serial: meter.serial,
          model: meter.meter_model.name,
          validated: meter.validated,
          state: meter.state,
          instalationDate: meter.instalation_date,
          retirementDate: meter.retirement_date,
          last_report: meter.lastReport,
        });
      });

      return { meters: formattedMeters, deviceId };
    } catch (error: any) {
      if (error.response.status === 404) {
        return { meters: [], deviceId };
      }
      return rejectWithValue(error);
    }
  }
);

export const getDeviceDetail = createAsyncThunk(
  "device/getDeviceDetail",
  async (deviceId: string, { rejectWithValue }) => {
    try {
      const res = await api.get<DeviceDetail>(`/verification/smartbits/${deviceId}`);
      return res.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const associateMeters = createAsyncThunk(
  "device/associateMeter",
  async ({ deviceId, serial, isPreEnlistment }: IAssociateMeter, { rejectWithValue, dispatch }) => {
    try {
      const detail = await getMetersDetail(serial);

      if (!detail) {
        dispatch(setMeterNotFound(true));
        return rejectWithValue(404);
      }

      const errors = verifyPreEnlistment(detail);

      if (errors.length > 0) {
        dispatch(setPreEnlistmentErrors(errors));
        return rejectWithValue(400);
      }

      await associateMeter(deviceId, serial);
      const res = await getMetersDetail(serial);
      const lastReport = isPreEnlistment ? await getPreEnlistmentLastReports(serial) : await getLastReports(serial);
      const newMeter: MeterData = {
        id: res.id,
        model: res.meter_model.name,
        serial: res.serial,
        state: res.state,
        validated: res.validated,
        instalationDate: res.instalation_date,
        retirementDate: res.retirement_date,
        last_report: lastReport,
      };

      return { newMeter };
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const associateInverters = createAsyncThunk(
  "device/associateInverters",
  async ({ deviceId, serial, isPreEnlistment }: IAssociateMeter, { rejectWithValue, dispatch }) => {
    try {
      const detail = await getInverterDetail(serial);

      if (!detail) {
        dispatch(setMeterNotFound(true));
        return rejectWithValue(404);
      }

      const errors = verifyPreEnlistmentInverter(detail);

      if (errors.length > 0) {
        dispatch(setPreEnlistmentErrors(errors));
        return rejectWithValue(400);
      }

      await associateInverter(deviceId, serial);
      const res = await getInverterDetail(serial);
      const lastReport = isPreEnlistment
        ? await getPreEnlistmentInverterLastReports(serial)
        : await getLastReportsInverters(serial);
      const newInverter: InverterData = {
        id: res.id,
        model: res.sfv_inverter_model.model,
        serial: res.serial,
        state: res.sfv_inverter_state.state ?? "",
        validated: res.sfv_inverter_state.state == "Validated",
        instalationDate: res.installation_date,
        retirementDate: res.retirement_date,
        last_report: lastReport,
      };
      return { newInverter };
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const dissasociateMeterFromDevice = createAsyncThunk(
  "device/dissasociateMeterFromDevice",
  async ({ deviceId, serial, meterIndex }: IDissaciateMeter, { rejectWithValue }) => {
    try {
      await dissociateMeter(deviceId, serial);

      return { meterIndex };
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const disassociateInverterFromDevice = createAsyncThunk(
  "device/disassociateInverterFromDevice",
  async ({ deviceId, serial, meterIndex }: IDissaciateMeter, { rejectWithValue }) => {
    try {
      await dissociateInverter(deviceId, serial);

      return { meterIndex };
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);
