import { createAsyncThunk } from "@reduxjs/toolkit";
import { getLastReports, getMeterDevice, getMetersDetail } from "../../services/meters";
import { MeterData } from "../../models/Meter";

export const getMeterDetail = createAsyncThunk(
  "meter/getMeterDetail",
  async (serial: string, { rejectWithValue }) => {
    try {
      const meterDetail = await getMetersDetail(serial);
      const lastReport = await getLastReports(serial);
      const deviceId = await getMeterDevice(meterDetail.id);

      const data: MeterData = {
        id: meterDetail.id,
        model: meterDetail.meter_model.name,
        serial: meterDetail.serial,
        state: meterDetail.state,
        validated: meterDetail.validated,
        instalationDate: meterDetail.instalation_date,
        retirementDate: meterDetail.retirement_date,
        deviceId,
        last_report: lastReport,
      };

      return data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);
