import { Box, CustomAlert, FormControl, LoadingButton, Typography, useFormik } from "@enerbit/base";
import ContentBox from "../../components/ContentBox/ContentBox";
import CustomTextField from "../../components/CustomTextfield/CustomTextField";
import { preEnlistmenSchema } from "../../schemas/device";
import SmartBitDetail from "../../components/SmartBitDetail/SmartBitDetail";
import { useEffect, useState } from "react";
import { DeviceDetail } from "../../models/device";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../store/store";
import { getDeviceDetail } from "../../store/thunks/device";
import {
  resetDeviceState,
  setHasAssociatedMeters,
  syncTempAssociatedMeters,
} from "../../store/slices/device";
import { resetMeterState } from "../../store/slices/meter";

const PreEnlistment = () => {
  const { loading, error, deviceDetail, associatedMeters } = useSelector(
    (state: RootState) => state.device
  );

  const dispatch = useDispatch<AppDispatch>();

  const formik = useFormik({
    initialValues: { deviceId: "" },
    onSubmit: (values) => {
      dispatch(resetMeterState());
      dispatch(resetDeviceState());
      dispatch(getDeviceDetail(values.deviceId));
    },
    validationSchema: preEnlistmenSchema,
  });

  useEffect(() => {
    // dispatch(setHasAssociatedMeters(associatedMeters.length > 0));
  }, [associatedMeters]);

  return (
    <Box sx={{ mt: 3 }}>
      <ContentBox>
        <form onSubmit={formik.handleSubmit}>
          <Box sx={{ display: "flex" }}>
            <Box sx={{ width: "85%" }}>
              <FormControl fullWidth sx={{ mr: 1 }}>
                <Typography>ID del smartBit</Typography>
                <CustomTextField
                  {...formik.getFieldProps("deviceId")}
                  error={!!formik.errors.deviceId && formik.touched.deviceId}
                  helperText={formik.touched.deviceId && formik.errors.deviceId}
                  disabled={loading}
                />
              </FormControl>
            </Box>
            <LoadingButton
              variant='contained'
              color='secondary'
              type='submit'
              loading={loading}
              sx={{ mt: 2.7, ml: 2, height: "50px", width: "15%" }}>
              Buscar
            </LoadingButton>
          </Box>
        </form>
      </ContentBox>
      {error && !loading && (
        <ContentBox mt={2}>
          <CustomAlert
            severity='error'
            text='Error consultando la información.'
            onClose={() => {}}
          />
        </ContentBox>
      )}
      {!error && !loading && deviceDetail && (
        <ContentBox mt={2}>
          <SmartBitDetail isPreEnlistment={true} />
        </ContentBox>
      )}
    </Box>
  );
};

export default PreEnlistment;
