import { Box, Button, CloseIcon, enerbitColors, Modal, Typography } from "@enerbit/base";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { AppDispatch, RootState } from "../../store/store";
import MeterItem from "./MeterItem";
import { MeterData } from "../../models/Meter";
import {
  removeAssociatedMeter,
  setHasAssociatedMeters,
  syncTempAssociatedMeters,
} from "../../store/slices/device";
import { dissociateMeter } from "../../services/meters";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  p: 3,
  borderRadius: "10px",
  width: "500px",
};

interface Props {
  open: boolean;
  handleClose: () => void;
}

enum Steps {
  CONFIRM = 1,
  DISSASOCIATE = 2,
}

export type disassociateStatus = "error" | "success" | "waiting" | "loading";

const DisassociateMetersBulk = ({ open, handleClose }: Props) => {
  const { associatedMeters, tempAssociatedMeters, deviceDetail } = useSelector(
    (state: RootState) => state.device
  );

  const dispacth = useDispatch<AppDispatch>();

  const [currentStep, setSteps] = useState<Steps>(Steps.CONFIRM);
  const [results, setResults] = useState<Record<
    string,
    { status: disassociateStatus; data: MeterData }
  > | null>(null);

  const onClose = () => {
    setSteps(Steps.CONFIRM);
    setResults(null);
    handleClose();
  };

  const fetchSequentially = async () => {
    if (!deviceDetail) return;
    for (const meter of tempAssociatedMeters) {
      setResults((prev) => {
        const nD = { ...prev };
        nD[meter.serial] = { ...nD[meter.serial], status: "loading" };
        return nD;
      });
      try {
        await dissociateMeter(deviceDetail.id, meter.serial);
        setResults((prev) => {
          const nD = { ...prev };
          nD[meter.serial] = { ...nD[meter.serial], status: "success" };
          return nD;
        });
        dispacth(removeAssociatedMeter(meter.serial));
      } catch (error) {
        setResults((prev) => {
          const nD = { ...prev };
          nD[meter.serial] = { ...nD[meter.serial], status: "error" };
          return nD;
        });
      }
    }
    return [];
  };

  useEffect(() => {
    if (open) {
      dispacth(syncTempAssociatedMeters(associatedMeters));
      setResults((prev) => {
        const nD = { ...prev };

        associatedMeters.forEach((meter) => {
          nD[meter.serial] = { data: meter, status: "waiting" };
        });

        return nD;
      });
    } else {
      dispacth(syncTempAssociatedMeters([]));
      setResults(null);
    }
  }, [open]);

  useEffect(() => {
    if (currentStep === Steps.DISSASOCIATE) {
      fetchSequentially();
    }
  }, [currentStep]);

  return (
    <Modal open={open} onClose={onClose}>
      <Box sx={style}>
        <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
          {currentStep === Steps.DISSASOCIATE ? (
            <Typography
              sx={{ fontWeight: 700, color: enerbitColors.primary.main, fontSize: "24px" }}>
              Desasociando medidores
            </Typography>
          ) : (
            <div></div>
          )}
          <Button className='back-btn' variant='outlined' onClick={onClose}>
            <CloseIcon />
          </Button>
        </Box>
        {currentStep === Steps.CONFIRM && (
          <Box>
            <Typography
              sx={{ textAlign: "center", fontSize: "24px", color: enerbitColors.primary.main }}>
              ¿Estás seguro de desasociar todos los medidores?
            </Typography>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                gap: 2,
                mt: 1,
              }}>
              <Button fullWidth onClick={onClose}>
                Cancelar
              </Button>
              <Button
                fullWidth
                variant='contained'
                color='secondary'
                onClick={() => {
                  setSteps(Steps.DISSASOCIATE);
                }}>
                Confirmar
              </Button>
            </Box>
          </Box>
        )}
        {currentStep === Steps.DISSASOCIATE && (
          <Box>
            <Typography sx={{ mb: 2 }}>
              No cierres esta ventana. Espera mientras se desasocian todos los medidores.
            </Typography>
            {results &&
              Object.keys(results).map((serial) => (
                <Box sx={{ mb: 2 }}>
                  <MeterItem key={serial} result={results[serial]} />
                </Box>
              ))}
          </Box>
        )}
      </Box>
    </Modal>
  );
};

export default DisassociateMetersBulk;
