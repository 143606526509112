import { Box, Button, Typography, useFormik } from "@enerbit/base";
import { useDispatch, useSelector } from "react-redux";
import { MAX_METERS_PER_DEVICE } from "../../constants";
import { MeterData } from "../../models/Meter";
import { DeviceDetail } from "../../models/device";
import { associateMeterSchema } from "../../schemas/device";
import { setOpenAssociateMeterModal, setPreEnlistmentErrors } from "../../store/slices/device";
import { AppDispatch, RootState } from "../../store/store";
import { associateMeters } from "../../store/thunks/device";
import AssociateModal from "../AssociateModal/AssociateModal";
import MeterCard from "../MeterCard/MeterCard";
import MeterContainer from "../MeterContainer/MeterContainer";

interface Props {
  isPreEnlistment?: boolean;
  meters: MeterData[];
  device?: DeviceDetail;
}

const Meters = ({ isPreEnlistment, meters, device }: Props) => {
  const { preEnlistmentErrors, meterNotFound, openAssociateMeterModal, associatingMeter, hasPrevMeters } = useSelector(
    (state: RootState) => state.device
  );

  const dispatch = useDispatch<AppDispatch>();

  const onAssociate = async (serial: string) => {
    if (!device) return;
    dispatch(associateMeters({ deviceId: device.id, serial, isPreEnlistment }));
  };

  const formik = useFormik({
    initialValues: { serial: "" },
    onSubmit: (values) => {
      onAssociate(values.serial);
    },
    validationSchema: associateMeterSchema,
  });

  const handleModalClose = () => {
    formik.resetForm();
    dispatch(setPreEnlistmentErrors([]));
    dispatch(setOpenAssociateMeterModal(false));
  };

  return (
    <>
      <Box>
        <MeterContainer>
          {meters.length === 0 && (
            <Box sx={{ display: "flex", alignItems: "center", justifyContent: "center", mb: 2 }}>
              <Typography sx={{ fontSize: "16px", color: "#667085" }}>Sin medidores asociados</Typography>
            </Box>
          )}
          {meters.map((meter, index) => (
            <MeterCard key={meter.id} meterIndex={index} meter={meter} isPreEnlistment={isPreEnlistment} />
          ))}
          {isPreEnlistment && device && !device.is_active && !hasPrevMeters && (
            <Box sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
              <Button
                color="secondary"
                variant="contained"
                disabled={meters.length === MAX_METERS_PER_DEVICE}
                sx={{ width: "194px" }}
                onClick={() => dispatch(setOpenAssociateMeterModal(true))}
              >
                Prealistar medidor
              </Button>
            </Box>
          )}
        </MeterContainer>
      </Box>
      <AssociateModal
        open={openAssociateMeterModal}
        handleClose={handleModalClose}
        formik={formik}
        loading={associatingMeter}
        fecthError={false}
        enlistmentErrors={preEnlistmentErrors}
        notFound={meterNotFound}
      />
    </>
  );
};

export default Meters;
