import { api } from "@enerbit/base";
import { AssociatedMeters, DeviceDetail, ISignal, ISmartBitEvents } from "../models/device";

export const getComunicationBySerial = async (serial: string) => {
  const { data } = await api.get(`inventory/comunications/?serie=${serial}`);

  if (data.items.length === 0) {
    return;
  }
  const comunication = data.items[0];
  const formatResponse = {
    imei: comunication?.imei,
    ip: comunication?.ip,
    mac: comunication?.mac,
  };

  return formatResponse;
};

export const validDeviceSignal = async (deviceId: string) => {
  const { data } = await api.get<ISignal[]>(`/verification/smartbits/signal?device_id=${deviceId}`);
  // const lastSignal = data[data.length - 1];

  return data;
};

export const getDeviceDetail = async (deviceId: string) => {
  const res = await api.get<DeviceDetail>(`/verification/smartbits/${deviceId}`);

  return res.data;
};

export const getDeviceEvents = async (deviceId: string, since: string, until: string) => {
  const res = await api.get<ISmartBitEvents>(
    `/verification/smartbits/events?device_id=${deviceId}&since=${since}&until=${until}`
  );

  return res.data.items;
};

export const getAssociatedMeters = async (deviceId: string) => {
  const res = await api.get<AssociatedMeters[]>(
    `/verification/smartbits/associated-meters?device_id=${deviceId}`
  );

  return res.data;
};
