import type { PayloadAction } from "@reduxjs/toolkit";
import { createSlice } from "@reduxjs/toolkit";
import { MeterData } from "../../models/Meter";
import { DeviceDetail } from "../../models/device";
import {
  associateMeters,
  disassociateInverterFromDevice,
  dissasociateMeterFromDevice,
  getAssociateMetersByDeviceId,
  getDeviceDetail,
} from "../thunks/device";

export interface deviceState {
  loading: boolean;
  deviceDetail: null | DeviceDetail;
  error: boolean;
  loadingAssociatedMeters: boolean;
  errorFetchingAssociatedMeters: boolean;
  hasPrevMeters: boolean;
  associatedMeters: MeterData[];
  tempAssociatedMeters: MeterData[];
  meterNotFound: boolean;
  preEnlistmentErrors: string[];
  openAssociateMeterModal: boolean;
  associatingMeter: boolean;
  openDissaociateMeterModal: boolean;
  dissasociatingMeter: boolean;
  errorDisassociatingMeter: boolean;
  errorDisassociatingInverter: boolean;
  dissasociatingInverter: boolean;
  openDissaociateInverterModal: boolean;
}

const initialState: deviceState = {
  loading: false,
  deviceDetail: null,
  error: false,
  hasPrevMeters: false,
  associatedMeters: [],
  tempAssociatedMeters: [],
  loadingAssociatedMeters: false,
  errorFetchingAssociatedMeters: false,
  meterNotFound: false,
  preEnlistmentErrors: [],
  openAssociateMeterModal: false,
  associatingMeter: false,
  openDissaociateMeterModal: false,
  dissasociatingMeter: false,
  errorDisassociatingMeter: false,
  errorDisassociatingInverter: false,
  dissasociatingInverter: false,
  openDissaociateInverterModal: false,
};

export const deviceSlice = createSlice({
  name: "device",
  initialState,
  reducers: {
    setLoading: (state, { payload }: PayloadAction<boolean>) => {
      state.loading = payload;
    },
    setError: (state, { payload }: PayloadAction<boolean>) => {
      state.error = payload;
    },
    setErrorFetchingAssociatedMeters: (state, { payload }: PayloadAction<boolean>) => {
      state.errorFetchingAssociatedMeters = payload;
    },
    setMeterNotFound: (state, { payload }: PayloadAction<boolean>) => {
      state.meterNotFound = payload;
    },
    setPreEnlistmentErrors: (state, { payload }: PayloadAction<string[]>) => {
      state.preEnlistmentErrors = payload;
    },
    setOpenAssociateMeterModal: (state, { payload }: PayloadAction<boolean>) => {
      state.openAssociateMeterModal = payload;
    },
    setOpenDissaociateMeterModal: (state, { payload }: PayloadAction<boolean>) => {
      state.openDissaociateMeterModal = payload;
    },
    setOpenDissaociateInverterModal: (state, { payload }: PayloadAction<boolean>) => {
      state.openDissaociateInverterModal = payload;
    },
    setErrorDisassociatingMeters: (state, { payload }: PayloadAction<boolean>) => {
      state.errorDisassociatingMeter = payload;
    },
    resetDeviceState: (state) => {
      state.loading = false;
      state.deviceDetail = null;
      state.error = false;
      state.hasPrevMeters = false;
      state.associatedMeters = [];
      state.loadingAssociatedMeters = false;
      state.errorFetchingAssociatedMeters = false;
      state.meterNotFound = false;
      state.preEnlistmentErrors = [];
      state.openAssociateMeterModal = false;
      state.associatingMeter = false;
      state.openDissaociateMeterModal = false;
      state.openDissaociateInverterModal = false;
      state.dissasociatingMeter = false;
      state.errorDisassociatingMeter = false;
    },
    removeAssociatedMeter: (state, { payload }: PayloadAction<string>) => {
      const index = state.associatedMeters.findIndex((item) => item.serial === payload);
      state.associatedMeters.splice(index, 1);
    },
    syncTempAssociatedMeters: (state, { payload }) => {
      state.tempAssociatedMeters = payload;
    },
    setHasAssociatedMeters: (state, { payload }: PayloadAction<boolean>) => {
      state.hasPrevMeters = payload;
    },
  },
  extraReducers: (builder) => {
    // Get device detail
    builder.addCase(getDeviceDetail.fulfilled, (state, { payload }) => {
      state.deviceDetail = payload;
      state.loading = false;
      state.error = false;
    });
    builder.addCase(getDeviceDetail.pending, (state) => {
      state.loading = true;
      state.error = false;
      state.deviceDetail = null;
    });
    builder.addCase(getDeviceDetail.rejected, (state) => {
      state.loading = false;
      state.error = true;
      state.deviceDetail = null;
    });

    // Get associated meters by device id
    builder.addCase(getAssociateMetersByDeviceId.fulfilled, (state, { payload }) => {
      state.hasPrevMeters = payload.meters.length > 0;
      state.associatedMeters = payload.meters;
      state.loadingAssociatedMeters = false;
      state.errorFetchingAssociatedMeters = false;
    });
    builder.addCase(getAssociateMetersByDeviceId.rejected, (state) => {
      state.loadingAssociatedMeters = false;
      state.errorFetchingAssociatedMeters = true;
      state.hasPrevMeters = false;
      state.associatedMeters = [];
    });
    builder.addCase(getAssociateMetersByDeviceId.pending, (state) => {
      state.loadingAssociatedMeters = true;
      state.errorFetchingAssociatedMeters = false;
      state.hasPrevMeters = false;
      state.associatedMeters = [];
    });

    // Associate meter to device
    builder.addCase(associateMeters.pending, (state) => {
      state.associatingMeter = true;
      state.preEnlistmentErrors = [];
      state.meterNotFound = false;
    });
    builder.addCase(associateMeters.fulfilled, (state, { payload }) => {
      state.associatedMeters.push(payload.newMeter);
      state.associatingMeter = false;
      state.openAssociateMeterModal = false;
    });
    builder.addCase(associateMeters.rejected, (state) => {
      state.associatingMeter = false;
    });

    // Dissasociate meter from device
    builder.addCase(dissasociateMeterFromDevice.pending, (state) => {
      state.errorDisassociatingInverter = false;
      state.dissasociatingInverter = true;
    });
    builder.addCase(dissasociateMeterFromDevice.fulfilled, (state, { payload }) => {
      state.associatedMeters.splice(payload.meterIndex, 1);
      state.errorDisassociatingInverter = false;
      state.dissasociatingInverter = false;
      state.openDissaociateMeterModal = false;
    });
    builder.addCase(dissasociateMeterFromDevice.rejected, (state) => {
      state.errorDisassociatingInverter = true;
      state.dissasociatingInverter = false;
    });
    // Dissasociate inverter from device
    builder.addCase(disassociateInverterFromDevice.pending, (state) => {
      state.errorDisassociatingInverter = false;
      state.dissasociatingInverter = true;
    });
    builder.addCase(disassociateInverterFromDevice.fulfilled, (state, { payload }) => {
      state.associatedMeters.splice(payload.meterIndex, 1);
      state.errorDisassociatingInverter = false;
      state.dissasociatingInverter = false;
      state.openDissaociateInverterModal = false;
    });
    builder.addCase(disassociateInverterFromDevice.rejected, (state) => {
      state.errorDisassociatingMeter = true;
      state.dissasociatingMeter = false;
    });
  },
});

export const {
  setLoading,
  setError,
  setErrorFetchingAssociatedMeters,
  setMeterNotFound,
  setPreEnlistmentErrors,
  setOpenAssociateMeterModal,
  setOpenDissaociateMeterModal,
  setOpenDissaociateInverterModal,
  resetDeviceState,
  removeAssociatedMeter,
  syncTempAssociatedMeters,
  setHasAssociatedMeters,
  setErrorDisassociatingMeters,
} = deviceSlice.actions;
