import { CSSProperties } from "react";
import { Tooltip as ReactTooltip } from "react-tooltip";

interface Props {
  id: string;
  message: string;
  styles: CSSProperties;
}
const CustomTooltip = ({ id, message, styles }: Props) => {
  return (
    <ReactTooltip
      id={id}
      place='bottom'
      style={styles}>
      {message}
    </ReactTooltip>
  );
};

export default CustomTooltip;
