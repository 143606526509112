import { configureStore } from "@reduxjs/toolkit";
import { deviceSlice } from "./slices/device";
import { inverterSlice } from "./slices/inverter";
import { meterSlice } from "./slices/meter";

export const store = configureStore({
  reducer: {
    device: deviceSlice.reducer,
    meter: meterSlice.reducer,
    inverter: inverterSlice.reducer,
  },
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
