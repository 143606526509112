import { Box, Typography, styled } from "@enerbit/base";

interface Props {
  date: string;
  message: string;
  bgcolor: string;
  fontColor: string;
}

const EventLog = ({ date, message, bgcolor, fontColor }: Props) => {
  return (
    <Box
      sx={{
        backgroundColor: bgcolor,
        padding: "5px",
        display: "flex",
        alignItems: "center",
        gap: 2,
        mb: 1,
      }}>
      <Indicator bgcolor={fontColor}></Indicator>
      <Typography sx={{ fontSize: "12px", color: fontColor }}>{date}</Typography>
      <Typography sx={{ fontSize: "12px", color: fontColor }}>{message}</Typography>
    </Box>
  );
};

export default EventLog;

interface IndicatorProps {
  bgcolor: string;
}

const Indicator = styled("div")<IndicatorProps>(({ bgcolor }) => ({
  height: "25px",
  border: `2px solid ${bgcolor}`,
  marginLeft: "-5px",
  marginTop: "-10px",
  marginBottom: "-10px",
}));
