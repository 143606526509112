import { styled, Box } from "@enerbit/base";

const ContentBox = styled(Box)(() => ({
  width: "100%",
  backgroundColor: "#fff",
  borderRadius: "16px",
  padding: "24px",
}));

export default ContentBox;
