import { Box, Grid, Typography } from "@enerbit/base";
import { IMeterLastReport } from "../../models/Meter";
import MeterContainer from "../MeterContainer/MeterContainer";

interface Props {
  lastReport: IMeterLastReport;
}

const METER_VARIABLES_DATA: { label: string; key: keyof IMeterLastReport; units: string }[] = [
  { label: "Voltaje L1", key: "voltage_l1n", units: "V" },
  { label: "Voltaje L2", key: "voltage_l2n", units: "V" },
  { label: "Voltaje L3", key: "voltage_l3n", units: "V" },
  { label: "Corriente L1", key: "current_l1", units: "A" },
  { label: "Corriente L2", key: "current_l2", units: "A" },
  { label: "Corriente L3", key: "current_l3", units: "A" },
  { label: "Factor de potencia L1", key: "power_factor_l1", units: "" },
  { label: "Factor de potencia L2", key: "power_factor_l2", units: "" },
  { label: "Factor de potencia L3", key: "power_factor_l3", units: "" },
];

const METER_MEASUREMENTS: { label: string; key: keyof IMeterLastReport; units: string }[] = [
  { label: "Energía activa importada acumulada", key: "active_energy_imported", units: "kWh" },
  {
    label: "Energía reactiva importada acumulada",
    key: "reactive_energy_imported",
    units: "kVArh",
  },
  { label: "Energía activa exportada acumulada", key: "active_energy_exported", units: "kWh" },
  {
    label: "Energía reactiva exportada acumulada",
    key: "reactive_energy_exported",
    units: "kVArh",
  },
];

const LastReportData = ({ lastReport }: Props) => {
  const formattedNumber = (value: number) => {
    return value.toLocaleString("es-Es");
  };
  return (
    <Box>
      <Grid container columnSpacing={2} rowSpacing={2}>
        {METER_VARIABLES_DATA.map((item) => (
          <Grid item md={4} key={item.key}>
            <Typography sx={{ color: "#525A6A", fontSize: "12px" }}>{item.label}</Typography>
            <Typography sx={{ color: "#525A6A", fontSize: "16px", fontWeight: 700 }}>
              {formattedNumber(+lastReport[item.key])} {item.units}
            </Typography>
          </Grid>
        ))}
      </Grid>
      <MeterContainer mt={2}>
        <Grid container columnSpacing={2}>
          {METER_MEASUREMENTS.map((item) => (
            <Grid item md={3} key={item.key}>
              <Typography sx={{ color: "#525A6A", fontSize: "12px" }}>{item.label}</Typography>
              <Typography sx={{ color: "#525A6A", fontSize: "16px", fontWeight: 700 }}>
                {formattedNumber(+lastReport[item.key] / 1000)} {item.units}
              </Typography>
            </Grid>
          ))}
        </Grid>
      </MeterContainer>
    </Box>
  );
};

export default LastReportData;
