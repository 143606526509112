import { createSlice } from "@reduxjs/toolkit";
import { IInverterState } from "../../models/inverter";
import { getInverterDetails } from "../thunks/inverter";

const initialState: IInverterState = {
  inverter: null,
  loading: false,
  error: false,
};

export const inverterSlice = createSlice({
  name: "inverter",
  initialState,
  reducers: {
    resetInverterState: (state) => {
      state.inverter = null;
      state.loading = false;
      state.error = false;
    },
  },
  extraReducers(builder) {
    builder.addCase(getInverterDetails.pending, (state, { payload }) => {
      state.inverter = null;
      state.loading = true;
      state.error = false;
    });
    builder.addCase(getInverterDetails.fulfilled, (state, { payload }) => {
      state.inverter = payload;
      state.loading = false;
      state.error = false;
    });
    builder.addCase(getInverterDetails.rejected, (state, { payload }) => {
      state.inverter = null;
      state.loading = false;
      state.error = true;
    });
  },
});

export const { resetInverterState } = inverterSlice.actions;
