import { MeterData } from "../../models/Meter";
import { Box, CircularProgress, Typography } from "@enerbit/base";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import ErrorIcon from "@mui/icons-material/Error";
import { disassociateStatus } from "./DisassociateMetersBulk";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
interface Props {
  result: { status: disassociateStatus; data: MeterData };
}

const MeterItem = ({ result }: Props) => {
  return (
    <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
      <Box sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
        {result.status === "loading" && <CircularProgress size={20} />}
        {result.status === "success" && <CheckCircleOutlineIcon color='success' />}
        {result.status === "error" && <ErrorIcon color='error' />}
        {result.status === "waiting" && <MoreHorizIcon color='disabled' />}
      </Box>
      <Typography sx={{ color: "#525A6A", fontSize: "16px", fontWeight: 700 }}>
        {result.data.serial} - {result.data.model}
      </Typography>
    </Box>
  );
};

export default MeterItem;
