import { yup } from "@enerbit/base";

export const deviceSchema = yup.object().shape({
  deviceType: yup.string().required("Este campo es requerido"),
  deviceId: yup.string().when("deviceType", {
    is: "smartbit", // Aquí verifica si deviceType es igual a 'smartbit'
    then: yup
      .string()
      .matches(/^[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}$/, "Ingrese un id valido")
      .required("Este campo es requerido"),
  }),
  meterSerial: yup.string().when("deviceType", {
    is: "medidor",
    then: yup.string().required("Este campo es requerido"),
  }),
  inverterSerial: yup.string().when("deviceType", {
    is: "inversor",
    then: yup.string().required("Este campo es requerido"),
  }),
});

export const preEnlistmenSchema = yup.object().shape({
  deviceId: yup
    .string()
    .matches(/^[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}$/, "Ingrese un id valido")
    .required("Este campo es requerido"),
});

export const associateMeterSchema = yup.object().shape({
  serial: yup
    .string()
    .matches(/^[0-9]+$/, "Ingrese un serial valido")
    .required("Este campo es requerido"),
});
