import { Box, Button, Grid, SxProps, Typography } from "@enerbit/base";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import copy from "copy-to-clipboard";
import moment from "moment";
import { CSSProperties, useState } from "react";
import { IMeterLastReport, MeterData } from "../../models/Meter";
import { IInverterLastReport, InverterData } from "../../models/inverter";
import CustomTooltip from "../CustomTooltip/CustomTooltip";

interface Props {
  device: MeterData | InverterData | undefined;
  lastReport: IMeterLastReport | IInverterLastReport | undefined;
  loading: boolean;
  deviceType?: string;
}

const DataComponent = ({ device, lastReport, deviceType = "medidor", loading }: Props) => {
  const [copied, setCopied] = useState<boolean>(false);

  const GRID_SIZE = device?.deviceId ? 6 : 4;

  const copyButtonStyles: SxProps = { padding: "0 !important", minWidth: "unset !important" };
  const copyIconStyles: SxProps = { fontSize: "18px", color: "#525A6A" };
  const tooltipStyles: CSSProperties = {
    fontSize: "8px",
    height: "10px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  };

  const handleCopyToClipboard = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    text: string
  ) => {
    event.stopPropagation();
    copy(text);
    setCopied(true);
    setTimeout(() => {
      setCopied(false);
    }, 2000);
  };

  const lastReportInstanceOfMeter = (
    lastReport: IMeterLastReport | IInverterLastReport | undefined
  ): lastReport is IMeterLastReport => {
    return (lastReport as IMeterLastReport)?.time_local_utc !== undefined;
  };

  const lastReportInstanceOfInverter = (
    lastReport: IMeterLastReport | IInverterLastReport | undefined
  ): lastReport is IInverterLastReport => {
    return (lastReport as IInverterLastReport)?.timestamp !== undefined;
  };

  const timeValue = lastReportInstanceOfMeter(lastReport)
    ? lastReport.time_local_utc
    : lastReportInstanceOfInverter(lastReport)
    ? lastReport.timestamp
    : undefined;

  return (
    <>
      <Grid container columnSpacing={2} rowSpacing={2}>
        {device?.deviceId && (
          <Grid item md={GRID_SIZE}>
            <Typography sx={{ color: "#525A6A", fontSize: "12px" }}>ID smartBit</Typography>
            <Box sx={{ display: "flex", alignItems: "start", gap: "3px" }}>
              <Button
                sx={copyButtonStyles}
                data-tooltip-id='device-tooltip'
                onClick={(e) => handleCopyToClipboard(e, device?.deviceId || "")}>
                <ContentCopyIcon sx={copyIconStyles} />
              </Button>
              <Typography sx={{ color: "#525A6A", fontSize: "16px", fontWeight: 700 }}>
                {device.deviceId}
              </Typography>
            </Box>
          </Grid>
        )}
        <Grid item md={GRID_SIZE}>
          <Typography sx={{ color: "#525A6A", fontSize: "12px" }}>
            Serial del {deviceType}
          </Typography>
          <Box sx={{ display: "flex", alignItems: "center", gap: "3px" }}>
            <Button
              sx={copyButtonStyles}
              data-tooltip-id='serial-tooltip'
              onClick={(e) => handleCopyToClipboard(e, device?.serial ?? "")}>
              <ContentCopyIcon sx={copyIconStyles} />
            </Button>
            <Typography
              sx={{
                color: "#525A6A",
                fontSize: "16px",
                fontWeight: 700,
              }}>
              {device?.serial}
            </Typography>
          </Box>
        </Grid>
        <Grid item md={GRID_SIZE}>
          <Typography sx={{ color: "#525A6A", fontSize: "12px" }}>Modelo</Typography>
          <Typography sx={{ color: "#525A6A", fontSize: "16px", fontWeight: 700 }}>
            {device?.model}
          </Typography>
        </Grid>
        <Grid item md={GRID_SIZE}>
          <Typography sx={{ color: "#525A6A", fontSize: "12px" }}>Fecha último reporte</Typography>
          <Typography sx={{ color: "#525A6A", fontSize: "16px", fontWeight: 700 }}>
            {loading
              ? "Cargando..."
              : lastReport
              ? moment.utc(timeValue).subtract(5, "hour").format("YYYY-MM-DD HH:mm:ss")
              : "Error"}
          </Typography>
        </Grid>
      </Grid>
      <CustomTooltip
        id='serial-tooltip'
        message={copied ? "Copiado" : "Copiar"}
        styles={tooltipStyles}
      />
      <CustomTooltip
        id='device-tooltip'
        message={copied ? "Copiado" : "Copiar"}
        styles={tooltipStyles}
      />
    </>
  );
};

export default DataComponent;
