import moment from "moment";
import { deviceEventsType, metersEventsTypes } from "../constants";
import { Meter, MeterStates } from "../models/Meter";
import { ChartDataModel, Event, EventLogModel, ISignal } from "../models/device";
import { IInverter, IInverterEvent } from "../models/inverter";

export const mapNamesToValues = (signalStatus: string) => {
  switch (signalStatus) {
    case "EXCELENTE":
      return 4;
    case "BUENA":
      return 3;
    case "REGULAR":
      return 2;
    case "DEFICIENTE":
      return 1;
    case "MALA":
      return 0;
    default:
      return 0;
  }
};

export const mapValuesToNames = (value: number) => {
  switch (value) {
    case 4:
      return "Excelente";
    case 3:
      return "Buena";
    case 2:
      return "Regular";
    case 1:
      return "Deficiente";
    case 0:
      return "Mala";
    default:
      return "";
  }
};

export const formatChartData = (data: ISignal[]) => {
  const latestDataPerMinute: Record<string, any> = {};

  data.forEach((entry) => {
    const timeMinute = entry.time_sender.slice(0, 16); // Tomamos solo la parte de la fecha y la hora hasta los minutos
    if (
      !latestDataPerMinute[timeMinute] ||
      entry.time_sender > latestDataPerMinute[timeMinute].time_sender
    ) {
      latestDataPerMinute[timeMinute] = entry;
    }
  });

  const result: ISignal[] = Object.values(latestDataPerMinute);

  const signals: ChartDataModel[] = result.map((item) => {
    const name = item.signal_status;
    const label = moment(item.time_sender).utcOffset("-05:00").format("HH:mm");
    const value = mapNamesToValues(item.signal_status);
    return { name, label, value };
  });
  return signals;
};

type deviceType = "meter" | "inverter" | "smartbit"

export const formatEventLog = (event: Event, deviceType: deviceType ): EventLogModel => {
  const message = (deviceType === 'meter' || deviceType === 'inverter') ? formatMeterEventMessage(event, deviceType) : formatDeviceEventMessage(event);
  const date = moment.utc(event.time_sender).subtract(5, "hour").format("YYYY-MM-DD HH:mm:ss");
  const isError = event.event_types.event === "Error";
  const fontcolor = isError ? "#F04438" : "#667085";
  const bgcolor = isError ? "#FEECEB" : "#EAF0FA";

  return { message, date, fontcolor, bgcolor, isError };
};

export const verifyPreEnlistment = (meter: Meter) => {
  const errors: string[] = [];

  if (meter.validated) {
    errors.push("El medidor ya se encuentra validado");
  }

  if (
    meter.state.name === MeterStates.ACTIVATED ||
    meter.state.name === MeterStates.READY_TO_ACTIVATE
  ) {
    errors.push(`El medidor se encuentra ${meter.state.name.toLowerCase()}`);
  }

  if (meter.instalation_date && !meter.retirement_date) {
    errors.push("El medidor se encuentra instalado");
  }

  return errors;
};

export const verifyPreEnlistmentInverter = (inverter: IInverter) => {
  const errors: string[] = [];

  if (inverter.state == MeterStates.VALIDATED) {
    errors.push("El inversor ya se encuentra validado");
  }

  if (
    inverter.state === MeterStates.ACTIVATED ||
    inverter.state === MeterStates.READY_TO_ACTIVATE
  ) {
    errors.push(`El inversor se encuentra ${inverter.state.toLowerCase()}`);
  }

  if (inverter.installation_date && !inverter.retirement_date) {
    errors.push("El inversor se encuentra instalado");
  }

  return errors;
};

export const formatTime = (time: number) => {
  const minutes = Math.floor(time / 60000);
  const seconds = Math.floor((time % 60000) / 1000);

  return `${minutes}:${seconds < 10 ? "0" : ""}${seconds}`;
};

const formatDeviceEventMessage = (event: Event): string => {
  const payload = JSON.parse(event.event_payload);
  switch (event.event_types.code) {
    case "0":
      return getEventName(event.event_payload, false) ?? "Evento desconocido";
    case "1":
      return getEventName(event.event_types.event, false) ?? "Evento desconocido";
    case "2":
      return `Intervalo de mesaje: ${payload.msg_interval}seg`;
    case "3":
      return getEventName(event.event_types.event, false) ?? "Evento desconocido";
    case "4":
      return "Estado de la batería";
    default:
      return "Evento desconocido";
  }
};

const getEventPayload = (value: Event | IInverterEvent): value is IInverterEvent => true;

const formatMeterEventMessage = (event: Event | IInverterEvent, device: deviceType) => {
  const eventPayload = device === 'inverter'
    ? (event as IInverterEvent).event_payload.type
    : (event as Event).event_payload;
  switch (event.event_types.code) {
    case "0":
      const formattedPayload = JSON.parse(eventPayload);
      return metersEventsTypes[formattedPayload.err];
    case "1":
      return getEventName(eventPayload, true) == ""
        ? "Evento desconocido"
        : getEventName(eventPayload, true);
    case "2":
      return "Reloj sincronizado";
    case "Ocurrence":
      return "";
    case "8":
      return "Remote supply connection";
    case "9":
      return "Remote supply diconnection";
    case "10":
      return "Load Profile 1 write channels operation";
    case "11":
      return "Load Profile 2 write channels operation";
    case "12":
      return "Load Profile 1 write capture period operation";
    case "13":
      return "Load Profile 2 write capture period operation";
    case "14":
      return getEventName(eventPayload, true) == ""
        ? "Evento desconocido"
        : getEventName(eventPayload, true);
    case "18":
      return "Outage event";
    case "19":
      return "Restore event";
    default:
      return "Evento desconocido";
  }
};

export const getEventName = (payload: string, isMeter: boolean) => {
  let eventName: string = "";

  if (isMeter) {
    Object.keys(metersEventsTypes).forEach((key) => {
      if (payload.includes(key)) {
        eventName = metersEventsTypes[key];
      }
    });
  } else {
    eventName = deviceEventsType[payload];
  }

  return eventName;
};
