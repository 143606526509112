import { api } from "@enerbit/base";
import { MeterDeviceRelationship } from "../models/Meter";
import { IInverterDetail, IInverterEvents, IInverterLastReport } from "../models/inverter";

export const getLastReportsInverters = async (inverterSerial: string) => {
  try {
    const { data } = await api.get<IInverterLastReport>(
      `/verification/inverters/last-report/?inverter_serial=${inverterSerial}`
    );
    return data;
  } catch (error: any) {
    return;
  }
};

export const getInverterDetail = async (inverterSerial: string) => {
  const res = await api.get<IInverterDetail>(`/verification/inverters/?inverter_serial=${inverterSerial}`);

  return res.data.items[0];
};

export const getInverterEvents = async (serial: string, since: string, until: string) => {
  const res = await api.get<IInverterEvents>(
    `/verification/inverters/events/?start_datetime=${since}&end_datetime=${until}&inverter_serial=${serial}`
  );

  return res.data.items;
};

export const associateInverter = async (deviceId: string, inverterSerial: string) => {
  await api.post(`/verification/inverter/associate-inverter?device_id=${deviceId}&inverter_serial=${inverterSerial}`);
}; //nuevo

export const getPreEnlistmentInverterLastReports = async (inverterSerial: string) => {
  try {
    const { data } = await api.get<IInverterLastReport>(
      `/verification/inverter/pre-enlistment/last-report?inverter_serial=${inverterSerial}`
    );
    return data;
  } catch (error: any) {
    return;
  }
}; //nuevo

export const dissociateInverter = async (deviceId: string, inverterSerial: string) => {
  await api.delete(
    `/verification/smartbits/dissociate-inverter?device_id=${deviceId}&inverter_serial=${inverterSerial}`
  );
}; //nuevo

export const getInverterDevice = async (meterId: string) => {
  try {
    const relationship = await api.get<MeterDeviceRelationship>(
      `/electricity-supply-service/device/device-inverters/inverters/${meterId}/`
    );
    return relationship.data.device_meter.id;
  } catch (error) {
    return "Error";
  }
}; //nuevo
