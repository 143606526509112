import { Box, Typography, styled } from "@enerbit/base";

interface Props {
  title: string;
  labels: { label: string; color: string }[];
}

const EventsHeader = ({ title, labels }: Props) => {
  return (
    <Box sx={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
      <Typography color='primary' sx={{ fontSize: "16px", fontWeight: 700 }}>
        {title}
      </Typography>
      <Box sx={{ display: "flex", gap: 2 }}>
        {labels.map((item, index) => (
          <Box key={index} sx={{ display: "flex", alignItems: "center", gap: 1 }}>
            <Dots bgcolor={item.color} />
            <Typography sx={{ color: item.color, fontSize: "12px" }}>{item.label}</Typography>
          </Box>
        ))}
      </Box>
    </Box>
  );
};

export default EventsHeader;

interface DotsProps {
  bgcolor: string;
}

const Dots = styled("div")<DotsProps>(({ bgcolor }) => ({
  backgroundColor: bgcolor,
  width: "8px",
  height: "8px",
  borderRadius: "50%",
}));
