import { Button, ExpandMoreIcon, Typography } from "@enerbit/base";
import React from "react";

interface Props {
  expanded: boolean;
}

const AccordionButton = ({ expanded }: Props) => {
  return (
    <Button
      color='primary'
      variant='outlined'
      sx={{
        borderRadius: "12px !important",
        height: "25px",
        minWidth: "unset !important",
        display: "flex",
        transform: "none !important",
      }}>
      <Typography color={"primary"} sx={{ fontWeight: 700 }}>
        {expanded ? "Ocultar" : "Ver"}
      </Typography>
      <ExpandMoreIcon
        sx={{ transform: expanded ? "rotate(180deg) !important" : "none !important" }}
      />
    </Button>
  );
};

export default AccordionButton;
