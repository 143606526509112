import { useState, useEffect } from "react";

const useCountdownTimer = (initialTime: number) => {
  const [timeLeft, setTimeLeft] = useState<number>(initialTime);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setTimeLeft((prevTime) => prevTime - 1000);

      if (timeLeft <= 0) {
        clearInterval(intervalId);
      }
    }, 1000);

    return () => {
      clearInterval(intervalId);
    };
  }, [timeLeft]);

  const resetTimer = (newTime: number) => {
    setTimeLeft(newTime);
  };

  return { timeLeft, resetTimer };
};

export default useCountdownTimer;
