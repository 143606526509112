import {
  Box,
  CustomAlert,
  FormControl,
  LoadingButton,
  MenuItem,
  Select,
  Typography,
  styled,
  useFormik,
} from "@enerbit/base";
import { useDispatch, useSelector } from "react-redux";
import ContentBox from "../../components/ContentBox/ContentBox";
import CustomTextField from "../../components/CustomTextfield/CustomTextField";
import Inverter from "../../components/Inverters/Inverters";
import Meters from "../../components/Meters/Meters";
import SmartBitDetail from "../../components/SmartBitDetail/SmartBitDetail";
import { deviceSchema } from "../../schemas/device";
import { resetDeviceState, setError } from "../../store/slices/device";
import { AppDispatch, RootState } from "../../store/store";
import { getDeviceDetail } from "../../store/thunks/device";
import { getInverterDetails } from "../../store/thunks/inverter";
import { getMeterDetail } from "../../store/thunks/meter";
import { resetMeterState } from "../../store/slices/meter";

enum DeviceMenuOptions {
  SMARTBIT = "smartbit",
  METER = "meter",
  INVERSOR = "inversor",
}

const MENU_ITEMS: { label: string; value: DeviceMenuOptions }[] = [
  { label: "smartBit", value: DeviceMenuOptions.SMARTBIT },
  { label: "Medidor", value: DeviceMenuOptions.METER },
  { label: "Inversor", value: DeviceMenuOptions.INVERSOR },
];

const Telemetry = () => {
  const {
    loading: loadingDevice,
    error: errorDevice,
    deviceDetail,
  } = useSelector((state: RootState) => state.device);

  const {
    meter,
    loading: loadingMeter,
    error: errorMeter,
  } = useSelector((state: RootState) => state.meter);

  const {
    inverter,
    loading: loadingInverter,
    error: errorInverter,
  } = useSelector((state: RootState) => state.inverter);

  const dispatch = useDispatch<AppDispatch>();

  const formik = useFormik({
    initialValues: { deviceId: "", deviceType: "smartbit", meterSerial: "", inverterSerial: "" },
    onSubmit: (values) => {
      dispatch(resetMeterState());
      dispatch(resetDeviceState());
      if (values.deviceType === DeviceMenuOptions.SMARTBIT) {
        dispatch(getDeviceDetail(values.deviceId));
      } else if (values.deviceType === DeviceMenuOptions.INVERSOR) {
        dispatch(getInverterDetails(values.inverterSerial));
      } else {
        dispatch(getMeterDetail(values.meterSerial));
      }
    },
    validationSchema: deviceSchema,
  });

  const deviceTypeSelected = formik.values.deviceType;

  return (
    <Box sx={{ mt: 3 }}>
      <ContentBox>
        <form onSubmit={formik.handleSubmit}>
          <Box sx={{ display: "flex", gap: 2 }}>
            <Box sx={{ width: "42%" }}>
              <FormControl fullWidth sx={{ mr: 1 }}>
                <Typography>Seleccionar dispositivo</Typography>
                <CustomSelect
                  {...formik.getFieldProps("deviceType")}
                  disabled={loadingDevice || loadingMeter}
                  error={!!formik.errors.deviceType && formik.touched.deviceType}
                  // helperText={formik.touched.deviceId && formik.errors.deviceId}
                >
                  {MENU_ITEMS.map((item) => (
                    <MenuItem key={item.value} value={item.value}>
                      {item.label}
                    </MenuItem>
                  ))}
                </CustomSelect>
              </FormControl>
            </Box>
            {deviceTypeSelected === "smartbit" && (
              <Box sx={{ width: "42%" }}>
                <FormControl fullWidth sx={{ mr: 1 }}>
                  <Typography>ID del smartBit</Typography>
                  <CustomTextField
                    {...formik.getFieldProps("deviceId")}
                    disabled={loadingDevice || loadingMeter}
                    error={!!formik.errors.deviceId && formik.touched.deviceId}
                    helperText={formik.touched.deviceId && formik.errors.deviceId}
                  />
                </FormControl>
              </Box>
            )}
            {deviceTypeSelected === "meter" && (
              <Box sx={{ width: "42%" }}>
                <FormControl fullWidth sx={{ mr: 1 }}>
                  <Typography>Serial del medidor</Typography>
                  <CustomTextField
                    {...formik.getFieldProps("meterSerial")}
                    disabled={loadingDevice || loadingMeter}
                    error={!!formik.errors.meterSerial && formik.touched.meterSerial}
                    helperText={formik.touched.meterSerial && formik.errors.meterSerial}
                  />
                </FormControl>
              </Box>
            )}
            {deviceTypeSelected === DeviceMenuOptions.INVERSOR && (
              <Box sx={{ width: "42%" }}>
                <FormControl fullWidth sx={{ mr: 1 }}>
                  <Typography>Serial del inversor</Typography>
                  <CustomTextField
                    {...formik.getFieldProps("inverterSerial")}
                    disabled={loadingInverter}
                    error={!!formik.errors.inverterSerial && formik.touched.inverterSerial}
                    helperText={formik.touched.inverterSerial && formik.errors.inverterSerial}
                  />
                </FormControl>
              </Box>
            )}
            <LoadingButton
              variant='contained'
              color='secondary'
              type='submit'
              loading={loadingDevice || loadingMeter || loadingInverter}
              sx={{ mt: 2.7, height: "50px", width: "15%" }}>
              Buscar
            </LoadingButton>
          </Box>
        </form>
      </ContentBox>
      {(errorDevice || errorMeter) && (!loadingDevice || !loadingMeter) && (
        <ContentBox mt={2}>
          <CustomAlert
            severity='error'
            text='Error consultando la información.'
            onClose={() => setError(false)}
          />
        </ContentBox>
      )}
      {(!errorDevice || !errorMeter) && (!loadingDevice || !loadingMeter) && deviceDetail && (
        <ContentBox mt={2}>
          <SmartBitDetail />
        </ContentBox>
      )}
      {(!errorDevice || !errorMeter) && (!loadingDevice || !loadingMeter) && meter && (
        <ContentBox mt={2}>
          <Meters meters={[meter]} />
        </ContentBox>
      )}
      {(!errorInverter || !errorMeter) && (!loadingInverter || !loadingMeter) && inverter && (
        <ContentBox mt={2}>
          <Inverter inverters={[inverter]} />
        </ContentBox>
      )}
    </Box>
  );
};

export default Telemetry;

const CustomSelect = styled(Select)`
  border-radius: 12px;
`;
