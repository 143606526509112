import { DeviceDetail } from "../../models/device";
import {
  Box,
  Button,
  CircularProgress,
  CustomAlert,
  Grid,
  List,
  ListItem,
  ListItemText,
  Typography,
  styled,
} from "@enerbit/base";
import SmartBitEvents from "../SmartBitEvents/SmartBitEvents";
import "../../styles/index.css";
import Meters from "../Meters/Meters";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../store/store";
import { getAssociateMetersByDeviceId } from "../../store/thunks/device";
import { setErrorFetchingAssociatedMeters } from "../../store/slices/device";
import CoverageChart from "../CoverageChart/CoverageChart";
import CoverageParams from "../CoverageParams/CoverageParams";
import DisassociateMetersBulk from "../DisassociateMetersBulk/DisassociateMetersBulk";

interface Props {
  isPreEnlistment?: boolean;
}

interface DeviceData {
  key: string;
  label: string;
}

const DATA: DeviceData[] = [
  { key: "imei", label: "IMEI" },
  { key: "mac_address", label: "MAC" },
  { key: "ip", label: "IP" },
  { key: "fw_version", label: "Versión del firmware" },
  { key: "net", label: "Red" },
];

const SmartBitDetail = ({ isPreEnlistment }: Props) => {
  const {
    deviceDetail,
    hasPrevMeters,
    associatedMeters,
    loadingAssociatedMeters: loading,
    errorFetchingAssociatedMeters: error,
  } = useSelector((state: RootState) => state.device);

  const [dissociateBulk, setDissociateBulk] = useState<boolean>(false);

  const dispatch = useDispatch<AppDispatch>();

  useEffect(() => {
    if (!deviceDetail) return;

    dispatch(getAssociateMetersByDeviceId({ deviceId: deviceDetail.id, isPreEnlistment }));
  }, []);

  const formatDeviceErrors = () => {
    if (!deviceDetail) return;

    const errors: string[] = [];

    if (deviceDetail.is_active) {
      errors.push("El smartBit ya se encuentra activo");
    }

    if (hasPrevMeters) {
      errors.push("El smartBit tiene medidores previamente asociados");
    }

    return (
      <Box my={2} sx={{ color: "#F04438" }}>
        <Typography sx={{ fontWeight: 700 }}>
          Este dispositivo no puede ser usado para prealistamiento por los siguientes motivos:
        </Typography>
        <List>
          {errors.map((error, index) => (
            <ListItem key={index + 1} disablePadding>
              <Typography variant='body1' component='span' sx={{ mr: 1 }}>
                •
              </Typography>
              <ListItemText primary={error} />
            </ListItem>
          ))}
        </List>
      </Box>
    );
  };

  return (
    <>
      {deviceDetail && (
        <Box>
          <Typography sx={{ color: "#858D9D" }}>ID</Typography>
          <Box>
            <Typography color='primary' sx={{ fontWeight: 700, fontSize: "20px" }}>
              {deviceDetail.id}
            </Typography>
          </Box>
          <Divisor />
          <Grid container columnSpacing={2}>
            {DATA.map((item, index) => (
              <Grid key={index} item md={12 / DATA.length}>
                <Typography sx={{ color: "#525A6A", fontSize: "12px" }}>{item.label}</Typography>
                <Typography sx={{ color: "#525A6A", fontSize: "16px", fontWeight: 700 }}>
                  {deviceDetail[item.key as keyof DeviceDetail] ?? "Sin registro"}
                </Typography>
              </Grid>
            ))}
          </Grid>
          <Grid container columnSpacing={2} rowSpacing={2} mt={3}>
            <Grid item md={6}>
              <CoverageChart deviceId={deviceDetail.id} operator={deviceDetail.operator} />
            </Grid>
            <Grid item md={6}>
              <SmartBitEvents device={deviceDetail} />
            </Grid>
            <Grid item md={12}>
              <CoverageParams device={deviceDetail} />
            </Grid>
          </Grid>
          <Box mt={3}>
            {!loading && !error && (
              <Box mb={3}>
                <Box
                  sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                  <Typography sx={{ color: "#525A6A", fontWeight: 700, fontSize: "22px" }}>
                    Medidores
                  </Typography>
                  {isPreEnlistment && associatedMeters.length > 0 && (
                    <Button
                      variant='outlined'
                      color='primary'
                      onClick={() => setDissociateBulk(true)}>
                      Desasociar todos los medidores
                    </Button>
                  )}
                </Box>
                {isPreEnlistment && !hasPrevMeters && !deviceDetail.is_active && (
                  <Typography sx={{ color: "#667085", fontSize: "16px" }}>
                    Prealista hasta 5 medidores
                  </Typography>
                )}
                {isPreEnlistment && (hasPrevMeters || deviceDetail.is_active)
                  ? formatDeviceErrors()
                  : null}
              </Box>
            )}
            {loading && (
              <Box sx={{ display: "flex", justifyContent: "center" }}>
                <CircularProgress />
              </Box>
            )}
            {!loading && !error && (
              <Meters
                isPreEnlistment={isPreEnlistment}
                meters={associatedMeters}
                device={deviceDetail}
              />
            )}
            {error && (
              <Box my={2}>
                <CustomAlert
                  severity='error'
                  text={`Error al obtener los medidores asociados medidor`}
                  onClose={() => dispatch(setErrorFetchingAssociatedMeters(false))}
                />
              </Box>
            )}
          </Box>
        </Box>
      )}
      <DisassociateMetersBulk open={dissociateBulk} handleClose={() => setDissociateBulk(false)} />
    </>
  );
};

export default SmartBitDetail;

const Divisor = styled("div")(() => ({
  width: "100%",
  borderBottom: "1px solid #A3A9B6",
  marginTop: "15px",
  marginBottom: "15px",
}));
