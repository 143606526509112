import { api } from "@enerbit/base";
import { IMeterLastReport, MeterDetail, MeterDeviceRelationship } from "../models/Meter";
import { ISmartBitEvents } from "../models/device";
import moment from "moment";

export const getLastReports = async (meterSerial: string) => {
  try {
    const { data } = await api.get<IMeterLastReport>(
      `/verification/meters/last-report?meter_serial=${meterSerial}`
    );
    // return moment.utc(data.time_local_utc).subtract(5, "hour").format("YYYY-MM-DD HH:mm:ss");
    return data;
  } catch (error: any) {
    return;
  }
};

export const getPreEnlistmentLastReports = async (meterSerial: string) => {
  try {
    const { data } = await api.get<IMeterLastReport>(
      `/verification/meters/pre-enlistment/last-report?meter_serial=${meterSerial}`
    );
    // return moment.utc(data.time_local_utc).subtract(5, "hour").format("YYYY-MM-DD HH:mm:ss");
    return data;
  } catch (error: any) {
    return;
  }
};

export const associateMeter = async (deviceId: string, meterSerial: string) => {
  await api.post(
    `/verification/smartbits/associate-meter?device_id=${deviceId}&meter_serial=${meterSerial}`
  );
};

export const dissociateMeter = async (deviceId: string, meterSerial: string) => {
  await api.delete(
    `/verification/smartbits/dissociate-meter?device_id=${deviceId}&meter_serial=${meterSerial}`
  );
};

export const getMetersDetail = async (meterSerial: string) => {
  const res = await api.get<MeterDetail>(`/meters/meters/?serial=${meterSerial}`);

  return res.data.items[0];
};

export const getMeterEvents = async (serial: string, since: string, until: string) => {
  const res = await api.get<ISmartBitEvents>(
    `/meters/meters-events/?start_datetime=${since}&end_datetime=${until}&meter_serial=${serial}`
  );

  return res.data.items;
};

export const getMeterDevice = async (meterId: string) => {
  try {
    const relationship = await api.get<MeterDeviceRelationship>(
      `/electricity-supply-service/device/device-meters/meters/${meterId}/`
    );
    return relationship.data.device_meter.id;
  } catch (error) {
    return "Error";
  }
};

export const changeMeterState = async (serial: string) => {};
