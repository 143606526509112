export const EVENTS_LABELS: { label: string; color: string }[] = [
  { label: "Error", color: "#F04438" },
  { label: "Informativo", color: "#667085" },
];

export const PARAM_CHART_LABELS: { label: string; color: string }[] = [
  { label: "Excelente", color: "#12B76A" },
  { label: "Buena", color: "#3B82F6" },
  { label: "Regular", color: "#F79009" },
  { label: "Mala", color: "#F04438" },
];

export const SIGNAL_STATUSES_COLOR: Record<string, { bgcolor: string; fontColor: string }> = {
  EXCELENTE: { bgcolor: "#E7F8F0", fontColor: "#12B76A" },
  BUENA: { bgcolor: "#EAF0FA", fontColor: "#3B82F6" },
  REGULAR: { bgcolor: "#FEF4E6", fontColor: "#F79009" },
  MALA: { bgcolor: "#FEECEB", fontColor: "#F04438" },
};

export const EVENTS_DELAY: number = 120000;
export const SIGNAL_DELAY: number = 60000;

// Meter events
// const errorComunication = '"type":"METER","reason":"METER_COMMUNICATION_FAILURE","err":-2'; // Error de hardware
// const errorComunicationThird = '"type":"METER","reason":"METER_COMMUNICATION_FAILURE","err":-3'; // Error de hardware
// const errorCredentials = '"type":"METER","reason":"METER_COMMUNICATION_FAILURE","err":-1'; // Error de credenciales
// const error = '"type":"METER","reason":"METER_COMMUNICATION_FAILURE","err":-5'; // Error de credenciales
const relayOn = '"reason":"ON","type":"RELAY"';
const relayOff = '"reason":"OFF","type":"RELAY"';
const tariffPlant = '"type":"PROMETHEUS","reason":"TARIFF_CHANGED","tariff":"PLANT"';
const tamper = '"type":"TAMPER"';

export const metersEventsTypes: Record<string, string> = {
  "-1": "Error credenciales -1",
  "-2": "Error cableado -2",
  "-3": "Error de comunicación -3",
  "-4": "Error de comunicación -4",
  "-5": "Error de comunicación -5",
  [relayOn]: "Relay on",
  [relayOff]: "Relay off",
  [tariffPlant]: "Tarifa planta",
  [tamper]: "Manipulación tapa bornera",
};

// Device events
const batteryStatus = "Battery Status";
const deviceError = "Error";
const lastBreath = "Last Breath";
const messageInterval = "Message Interval";
const mainSupplyReconnection = "Main Supply reconnection";
const resetTaskWdt = '{"reason":"TASK_WDT","type":"RESET"}';
const resetIntWdt = '{"reason":"INT_WDT","type":"RESET"}';
const resetExt = '{"reason":"EXT","type":"RESET"}';
const resetPowerOn = '{"reason":"POWERON","type":"RESET"}';
const resetSW = '{"reason":"SW","type":"RESET"}';
const panic = '{"reason":"PANIC","type":"RESET"}';

export const deviceEventsType: Record<string, string> = {
  [batteryStatus]: "Estado de la batería",
  [deviceError]: "Error de hardware",
  [lastBreath]: "Último suspiro",
  [messageInterval]: "Intervalo de mensaje",
  [mainSupplyReconnection]: "Reconexión de suministro principal",
  [resetTaskWdt]: "Error de Firmware/Hardware: Reset - TASK_WDT",
  [resetIntWdt]: "Error de Firmware/Hardware: Reset - INT_WDT",
  [resetExt]: "Error de Firmware/Hardware: Reset - EXT",
  [resetPowerOn]: "Error de Firmware/Hardware: Reset - POWERON",
  [resetSW]: "Error de Firmware/Hardware: Reset - SW",
  [panic]: "Error de Firmware/Hardware: Reset - PANIC",
};

export const EVENT_CODE_ERRORS = ["0"];

export const MAX_METERS_PER_DEVICE = 5;
