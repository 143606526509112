import { createSlice } from "@reduxjs/toolkit";
import { IMeterState } from "../../models/Meter";
import { getMeterDetail } from "../thunks/meter";

const initialState: IMeterState = {
  meter: null,
  loading: false,
  error: false,
};

export const meterSlice = createSlice({
  name: "meter",
  initialState,
  reducers: {
    resetMeterState: (state) => {
      state.meter = null;
      state.loading = false;
      state.error = false;
    },
  },
  extraReducers(builder) {
    builder.addCase(getMeterDetail.pending, (state, { payload }) => {
      state.meter = null;
      state.loading = true;
      state.error = false;
    });
    builder.addCase(getMeterDetail.fulfilled, (state, { payload }) => {
      state.meter = payload;
      state.loading = false;
      state.error = false;
    });
    builder.addCase(getMeterDetail.rejected, (state, { payload }) => {
      state.meter = null;
      state.loading = false;
      state.error = true;
    });
  },
});

export const { resetMeterState } = meterSlice.actions;
