import { styled, Box } from "@enerbit/base";

interface Props {
  height?: string;
  bgcolor?: string;
}

const ChartContainer = styled(Box)<Props>(({ height, bgcolor }) => ({
  height,
  border: "1px solid #A3A9B6",
  borderRadius: "16px",
  padding: "24px",
  backgroundColor: bgcolor,
}));

export default ChartContainer;
