import { Box, Typography, enerbitColors } from "@enerbit/base";

interface Props {
  title: string;
  subtitle: string;
}

const EmptyEvents = ({ title, subtitle }: Props) => {
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
        width: "100%",
      }}>
      <Typography sx={{ fontSize: "16px", color: enerbitColors.primary.main, fontWeight: 700 }}>
        {title}
      </Typography>
      <Typography sx={{ fontSize: "14px", color: "#667085" }}>{subtitle}</Typography>
    </Box>
  );
};

export default EmptyEvents;
