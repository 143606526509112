import { initEnviroment, PrivateRoute, ThemeConfig, NotiProvider, Box } from "@enerbit/base";
import { hot } from "react-hot-loader/root";
import { Provider } from "react-redux";
import { DeviceTelemetry } from "./pages/DeviceTelemetry";
import { store } from "./store/store";

export default hot(function Root() {
  initEnviroment({
    baseUrl: process.env.REACT_APP_BASE_URL,
  });

  return (
    <>
      <PrivateRoute>
        <Provider store={store}>
          <ThemeConfig>
            <NotiProvider>
              <DeviceTelemetry />
            </NotiProvider>
          </ThemeConfig>
        </Provider>
      </PrivateRoute>
    </>
  );
});
