export interface IMeterInfo {
  meter_id: string;
  meter_serial: string;
  meter_model: string;
  validated: boolean;
  instalation_date: string;
  associated?: boolean;
}

export interface IMeterLastReport {
  active_energy_imported: number;
  current_l1: number;
  current_l2: number;
  current_l3: number;
  power_factor_l1: number;
  power_factor_l2: number;
  power_factor_l3: number;
  reactive_energy_imported: number;
  time_local: string;
  time_local_utc: string;
  voltage_l1n: number;
  voltage_l2n: number;
  voltage_l3n: number;
  active_energy_exported: string;
  reactive_energy_exported: string;
}

export interface MeterDeviceRelationship {
  started_at: string;
  id: string;
  meter_id: string;
  device_meter: DeviceMeter;
  created_at: string;
  updated_at: string | null;
  ended_at: string | null;
}

export interface DeviceMeter {
  id: string;
  group_id: string | null;
  imei: string;
  mac_address: string;
  country_name: string;
  state_name: string;
  locality_name: string;
  organization_name: string;
  latitude: number | null;
  longitude: number | null;
  created_at: string;
  is_active: boolean;
  name: string;
}

export interface MeterDetail {
  items: Meter[];
  total: number;
  page: number;
  size: number;
  pages: number;
}

export interface Meter {
  serial: string;
  state_id: string;
  model_id: string;
  group_id: null;
  id: string;
  state: State;
  created_at: string;
  instalation_date: string;
  retirement_date: null | string;
  validated: boolean;
  meter_model: MeterModel;
}

export interface MeterModel {
  name: string;
  code: string;
  id: string;
  brand: State;
  meter_connection_type: Type;
  power_supply_type: Type;
  communication_protocol_type: CommunicationProtocolType;
}

export interface State {
  name: string;
  id: string;
}

export interface CommunicationProtocolType {
  code: string;
  name: string;
  description: string;
  communication_type: string;
  id: string;
  created_at: string;
  updated_at: null | string;
}

export interface Type {
  name: string;
  description: string;
  id: string;
}

export interface MeterData {
  id: string;
  serial: string;
  model: string;
  deviceId?: string;
  validated: boolean;
  state: State;
  instalationDate: string | null;
  retirementDate: string | null;
  last_report: IMeterLastReport | undefined;
}

export enum MeterStates {
  READY_TO_ACTIVATE = "Listo para activar",
  ACTIVATED = "Activado",
  MAINTENANCE = "Mantenimiento",
  RETIRED = "Retirado",
  LAB = "Laboratorio",
  VALIDATED = "Validado",
}

export interface IMeterState {
  meter: MeterData | null;
  loading: boolean;
  error: boolean;
}
