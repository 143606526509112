import {
  Box,
  CircularProgress,
  CustomAlert,
  MenuItem,
  Select,
  SelectChangeEvent,
  Typography,
  styled,
} from "@enerbit/base";
import moment from "moment";
import { useEffect, useState } from "react";

import { Line } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { SIGNAL_DELAY } from "../../constants";
import { formatChartData, mapValuesToNames, formatTime } from "../../helpers";
import { useInterval } from "../../hooks/useInterval";
import { ChartDataModel } from "../../models/device";
import { validDeviceSignal } from "../../services/device";
import ChartContainer from "../ChartContainer/ChartContainer";

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend);

enum FilterValues {
  HOUR = "hour",
}

interface Filters {
  value: FilterValues;
  label: string;
}

const FILTER_OPTIONS: Filters[] = [{ label: "Hora", value: FilterValues.HOUR }];

interface Props {
  operator: string;
  deviceId: string;
}

const CoverageChart = ({ operator, deviceId }: Props) => {
  const currentDate = moment().format("YYYY-MM-DD");
  const [filter, setFilter] = useState<FilterValues>(FilterValues.HOUR);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<boolean>(false);
  const [signal, setSignal] = useState<ChartDataModel[]>();

  const onChangeFilter = (event: SelectChangeEvent<unknown>) => {
    setFilter(event.target.value as FilterValues);
  };

  const getSignalStatus = async () => {
    // setError(false);
    setLoading(true);
    try {
      const signals = await validDeviceSignal(deviceId);
      setSignal(formatChartData(signals).reverse());
    } catch (error) {
      // setError(true);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getSignalStatus();
  }, []);

  const { timeLeft } = useInterval(async () => {
    await getSignalStatus();
  }, SIGNAL_DELAY);

  const data = {
    labels: signal ? signal.map((item) => item.label) : [],
    datasets: [
      {
        data: signal ? signal.map((item) => item.value) : [],
        fill: false,
        borderColor: "#FF7705",
        tension: 0.1,
      },
    ],
  };

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: { legend: { display: false } },
    scales: {
      y: {
        ticks: {
          callback: (value: any) => mapValuesToNames(value),
        },
        suggestedMin: 0,
        suggestedMax: 5,
      },
    },
  };

  return (
    <ChartContainer sx={{ position: "relative" }} height='430px'>
      {loading && (
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
          }}>
          <CircularProgress />
        </Box>
      )}
      {/* {error && (
        <CustomAlert
          text='Error consultando el estado de la señal'
          severity='error'
          onClose={() => setError(false)}
        />
      )} */}
      {!loading && (
        <>
          <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
            <Box>
              <Typography color='primary' sx={{ fontSize: "16px", fontWeight: 700 }}>
                Cobertura
              </Typography>
              <Typography sx={{ color: "#525A6A", fontSize: "12px" }}>
                Día: {currentDate}
              </Typography>
            </Box>
            {/* <CustomSelect value={filter} onChange={onChangeFilter}>
          {FILTER_OPTIONS.map((item) => (
            <MenuItem key={item.value} value={item.value}>
              {item.label}
            </MenuItem>
          ))}
        </CustomSelect> */}
          </Box>
          <Box my={1}>
            <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
              <ChartIndicator />
              <Typography sx={{ color: "#667085" }}>{operator}</Typography>
            </Box>
            {!loading && (
              <Box>
                <Typography sx={{ color: "#667085", fontSize: "14px" }}>
                  Se actualizará en {formatTime(timeLeft)}
                </Typography>
              </Box>
            )}
            {signal && (
              <Box sx={{ height: "300px", ml: "-12px", mr: "-20px" }}>
                <Line data={data} options={options} />
              </Box>
            )}
          </Box>
        </>
      )}
    </ChartContainer>
  );
};

export default CoverageChart;

const CustomSelect = styled(Select)`
  border-radius: 12px;
  height: 36px !important;
`;

const ChartIndicator = styled("div")(() => ({
  width: "36px",
  height: "2px",
  backgroundColor: "#FF7705",
}));
