import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  CircularProgress,
  SxProps,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  Typography,
  enerbitColors,
} from "@enerbit/base";
import moment from "moment";

import { SyntheticEvent, useEffect, useState } from "react";
import { SIGNAL_STATUSES_COLOR, SIGNAL_DELAY, PARAM_CHART_LABELS } from "../../constants";
import { formatTime } from "../../helpers";
import { useInterval } from "../../hooks/useInterval";
import { DeviceDetail, ISignal } from "../../models/device";
import { validDeviceSignal } from "../../services/device";
import AccordionButton from "../AccordionButton/AccordionButton";
import EventsHeader from "../EventsHeader/EventsHeader";
import StyledTableRow from "../StyledTableRow/StyledTableRow";

const TABLE_HEADERS: string[] = [
  "Fecha del evento (UTC)",
  "Paquetes perdidos",
  "Tiempo promedio",
  "Tiempo total",
  "Operador",
  "Calidad",
];

interface Props {
  device: DeviceDetail;
}

interface SignalEvents extends ISignal {
  bgcolor: string;
  fontColor: string;
}

const CoverageParams = ({ device }: Props) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [signalParams, setSignalParams] = useState<SignalEvents[]>([]);
  const [expanded, setExpanded] = useState<boolean>(false);

  const formatEvent = (event: ISignal): SignalEvents => {
    return {
      ...event,
      bgcolor: SIGNAL_STATUSES_COLOR[event.signal_status].bgcolor,
      fontColor: SIGNAL_STATUSES_COLOR[event.signal_status].fontColor,
    };
  };

  const fetchParams = async () => {
    setLoading(true);
    try {
      const res = await validDeviceSignal(device.id);
      const formatedSignals = res.map(formatEvent);

      setSignalParams((prev) => {
        const nD = [...prev];
        formatedSignals.reverse().forEach((item) => nD.unshift(item));
        return nD;
      });
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchParams();
  }, []);

  const { timeLeft } = useInterval(async () => {
    await fetchParams();
  }, SIGNAL_DELAY);

  const accordionSummaryStyles: SxProps = {
    // background: "#fff",
    borderRadius: expanded ? "16px 16px 0 0" : "16px",
    height: "auto !important",
    px: 2,
    py: 1,
    border: "1px solid #A3A9B6",
    minHeight: "70px !important",
    ".MuiAccordionSummary-expandIconWrapper": { transform: "none !important" },
  };

  const accordionDetailsStyles: SxProps = {
    p: 2,
    border: expanded ? `1px solid #A3A9B6` : null,
    borderRadius: "0 0 16px 16px",
  };

  const handleChange = (_: SyntheticEvent, isExpanded: boolean) => {
    setExpanded(isExpanded);
  };

  return (
    <Accordion
      expanded={expanded}
      onChange={handleChange}
      sx={{
        my: 2,
        boxShadow: "unset !important",
      }}>
      <AccordionSummary
        expandIcon={<AccordionButton expanded={expanded} />}
        sx={accordionSummaryStyles}>
        <Box>
          <Typography sx={{ fontWeight: 700, fontSize: "16px", color: enerbitColors.primary.main }}>
            Parámetros
          </Typography>
        </Box>
      </AccordionSummary>
      <AccordionDetails sx={accordionDetailsStyles}>
        <Box mb={1}>
          <EventsHeader title='' labels={PARAM_CHART_LABELS} />
        </Box>
        {!loading && (
          <Box mb={1}>
            <Typography sx={{ color: "#667085", fontSize: "14px" }}>
              Se actualizará en {formatTime(timeLeft)}
            </Typography>
          </Box>
        )}
        <TableContainer
          sx={{ mt: 1, overflowY: "auto", maxHeight: "230px", paddingRight: 1 }}
          component={Box}>
          <Table
            stickyHeader
            sx={{ minWidth: 650, borderSpacing: "0 7px !important" }}
            size='small'
            aria-label='a dense table'>
            <TableHead>
              <StyledTableRow bgcolor={enerbitColors.primary.main} fontcolor='#FFF'>
                {TABLE_HEADERS.map((item, index) => (
                  <TableCell key={index}>{item}</TableCell>
                ))}
              </StyledTableRow>
            </TableHead>
            <TableBody>
              {signalParams.map((param, index) => (
                <StyledTableRow
                  style={{ margin: "5px 0" }}
                  key={index}
                  bgcolor={param.bgcolor}
                  fontcolor={param.fontColor}>
                  <TableCell>
                    {moment(param.time_sender).utcOffset("-5:00").format("YYYY-MM-DD HH:mm:ss")}
                  </TableCell>
                  <TableCell>{param.lost_packages}</TableCell>
                  <TableCell>{param.avg_time}</TableCell>
                  <TableCell>{param.tt}</TableCell>
                  <TableCell>{param.operator}</TableCell>
                  <TableCell>{param.signal_status}</TableCell>
                </StyledTableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        {loading && (
          <Box sx={{ display: "flex", alignItems: "center", justifyContent: "center", mb: 1 }}>
            <CircularProgress sx={{ width: "10px", height: "10px" }} />
          </Box>
        )}
      </AccordionDetails>
    </Accordion>
  );
};

export default CoverageParams;
