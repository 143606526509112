import { useState } from "react";
import {
  Box,
  Button,
  CloseIcon,
  CustomAlert,
  LoadingButton,
  Modal,
  Typography,
} from "@enerbit/base";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../store/store";
import { setErrorDisassociatingMeters } from "../../store/slices/device";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  p: 3,
  borderRadius: "10px",
  width: "500px",
};

interface Props {
  title: string;
  open: boolean;
  handleClose: () => void;
  handleConfirm: () => Promise<void>;
}

const ConfirmationModal = ({ open, handleClose, handleConfirm, title }: Props) => {
  const { dissasociatingMeter: loading, errorDisassociatingMeter: error } = useSelector(
    (state: RootState) => state.device
  );
  const dispatch = useDispatch<AppDispatch>();

  const onConfirm = async () => {
    handleConfirm();
  };

  const onClose = () => {
    dispatch(setErrorDisassociatingMeters(false));
    handleClose();
  };

  return (
    <Modal open={open} onClose={handleClose}>
      <Box sx={style}>
        <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
          <Button className='back-btn' variant='outlined' onClick={onClose}>
            <CloseIcon />
          </Button>
        </Box>
        <Box my={1}>
          <Typography color='primary' sx={{ fontSize: "24px", textAlign: "center" }}>
            {title}
          </Typography>
        </Box>
        {error && (
          <Box my={2}>
            <CustomAlert
              severity='error'
              text={`Error. Inténtalo nuevamente`}
              onClose={() => dispatch(setErrorDisassociatingMeters(false))}
            />
          </Box>
        )}
        <Box sx={{ display: "flex", justifyContent: "space-between", gap: 2, mt: 2 }}>
          <Button fullWidth onClick={handleClose}>
            Cancelar
          </Button>
          <LoadingButton
            fullWidth
            variant='contained'
            loading={loading}
            onClick={onConfirm}
            sx={{ color: "#fff" }}
            color='secondary'>
            Confirmar
          </LoadingButton>
        </Box>
      </Box>
    </Modal>
  );
};

export default ConfirmationModal;
