import { styled, Box } from "@enerbit/base";

const MeterContainer = styled(Box)(() => ({
  width: "100%",
  backgroundColor: "#F2F4F7",
  borderRadius: "16px",
  padding: "24px",
}));

export default MeterContainer;
