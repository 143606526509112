import { Box, Typography } from "@enerbit/base";
import { useDispatch } from "react-redux";
import { AppDispatch } from "../store/store";
import { SyntheticEvent, useEffect, useState } from "react";
import { StyledTabs } from "../components/StyledTabs/StyledTabs";
import { StyledTab } from "../components/StyledTab/StyledTab";
import { TabPanel } from "../components/TabPanel/TabPanel";
import Telemetry from "./tabs/Telemetry";
import PreEnlistment from "./tabs/PreEnlistment";
import { resetDeviceState } from "../store/slices/device";
import { resetMeterState } from "../store/slices/meter";

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const DEFAULT_TAB: number = 0;

export const DeviceTelemetry = () => {
  const [value, setValue] = useState<number>(DEFAULT_TAB);

  const dispatch = useDispatch<AppDispatch>();

  const handleChange = (_event: SyntheticEvent, newValue: number) => {
    dispatch(resetMeterState());
    dispatch(resetDeviceState());
    setValue(newValue);
  };

  return (
    <Box sx={{ py: "40px", px: "50px" }}>
      <Typography color='primary' sx={{ fontSize: "28px", fontWeight: 400, mb: "12px" }}>
        Herramientas de verificación
      </Typography>
      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
        <StyledTabs value={value} onChange={handleChange}>
          <StyledTab label={<Box>Telemetría</Box>} {...a11yProps(0)} />
          <StyledTab label={<Box>Prealistamiento</Box>} {...a11yProps(1)} />
        </StyledTabs>
      </Box>
      <TabPanel value={value} index={0}>
        <Telemetry />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <PreEnlistment />
      </TabPanel>
    </Box>
  );
};
