import { createAsyncThunk } from "@reduxjs/toolkit";
import { InverterData } from "../../models/inverter";
import { getInverterDetail, getInverterDevice, getLastReportsInverters } from "../../services/inverters";

export const getInverterDetails = createAsyncThunk(
  "inverter/getInverterDetail",
  async (serial: string, { rejectWithValue }) => {
    try {
      const inverterDetail = await getInverterDetail(serial);
      const lastReport = await getLastReportsInverters(serial);
      const deviceId = await getInverterDevice(inverterDetail.id);

      const data: InverterData = {
        id: inverterDetail.id,
        model: inverterDetail.sfv_inverter_model.model,
        serial: inverterDetail.serial,
        state: inverterDetail.sfv_inverter_state.state ?? "",
        validated: inverterDetail.sfv_inverter_state.state == "Validated",
        instalationDate: inverterDetail.installation_date,
        retirementDate: inverterDetail.retirement_date,
        deviceId,
        last_report: lastReport,
      };

      return data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);
