import { useEffect, useRef, useState } from "react";
import useCountdownTimer from "./useCountdownTimer";

export function useInterval(callback: () => void, delay: number) {
  const savedCallback = useRef<() => void>();
  const { timeLeft, resetTimer } = useCountdownTimer(delay);

  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  useEffect(() => {
    function tick() {
      savedCallback.current!();
      resetTimer(delay);
    }

    if (delay !== null) {
      let id = setInterval(tick, delay);
      return () => clearInterval(id);
    }
  }, [delay]);

  return { timeLeft };
}
