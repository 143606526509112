import { useEffect, useState } from "react";
import { Box, CircularProgress, Typography } from "@enerbit/base";
import { EVENTS_DELAY, EVENTS_LABELS } from "../../constants";
import { useInterval } from "../../hooks/useInterval";
import ChartContainer from "../ChartContainer/ChartContainer";
import moment from "moment";
import EventLog from "../EventLog/EventLog";
import { DeviceDetail, EventLogModel } from "../../models/device";
import { formatEventLog, formatTime } from "../../helpers";
import { getDeviceEvents } from "../../services/device";
import EmptyEvents from "../EmptyEvents/EmptyEvents";
import EventsHeader from "../EventsHeader/EventsHeader";

interface Props {
  device: DeviceDetail;
}

const SmartBitEvents = ({ device }: Props) => {
  const [events, setEvents] = useState<EventLogModel[]>([]);
  const [since, setSince] = useState<string>(
    moment().startOf("day").format("YYYY-MM-DDTHH:mm:ssZZ")
  );
  const [until, setUntil] = useState<string>(moment().format("YYYY-MM-DDTHH:mm:ssZZ"));
  const [loading, setLoading] = useState<boolean>(false);

  const fetchEvents = async () => {
    setLoading(true);
    try {
      const res = await getDeviceEvents(device.id, since, until);
      const events = res;
      const formatedEvents = events.map((ev) => formatEventLog(ev, 'smartbit'));
      setEvents((prev) => {
        const nD = [...prev];
        formatedEvents.reverse().forEach((ev) => nD.unshift(ev));
        return nD;
      });
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
      setSince(() => until);
      setUntil(() => moment().add(2, "minute").format("YYYY-MM-DDTHH:mm:ssZZ"));
    }
  };

  useEffect(() => {
    fetchEvents();
  }, []);

  const { timeLeft } = useInterval(async () => {
    await fetchEvents();
  }, EVENTS_DELAY);

  return (
    <ChartContainer height='430px' sx={{ position: "relative" }}>
      <Box mb={1}>
        <EventsHeader title='Eventos smartBit' labels={EVENTS_LABELS} />
      </Box>
      {!loading && (
        <Box mb={1}>
          <Typography sx={{ color: "#667085", fontSize: "14px" }}>
            Se actualizará en {formatTime(timeLeft)}
          </Typography>
        </Box>
      )}
      <Box
        sx={{ width: "100%", overflowY: "auto", maxHeight: "calc(100% - 80px)", paddingRight: 1 }}>
        {loading && (
          <Box sx={{ display: "flex", alignItems: "center", justifyContent: "center", mb: 1 }}>
            <CircularProgress sx={{ width: "10px", height: "10px" }} />
          </Box>
        )}
        {events.map((ev, index) => (
          <EventLog
            key={index}
            date={ev.date}
            message={ev.message}
            bgcolor={ev.bgcolor}
            fontColor={ev.fontcolor}
          />
        ))}
        {events.length === 0 && !loading ? (
          <Box
            sx={{
              position: "absolute",
              top: "40%",
              left: "50%",
              transform: "translate(-50%,50%)",
              width: "100%",
            }}>
            <EmptyEvents
              title={"smartBit sin eventos"}
              subtitle='Los sentimos, no hay eventos en este momento'
            />
          </Box>
        ) : null}
      </Box>
    </ChartContainer>
  );
};

export default SmartBitEvents;
